import { css } from '@emotion/react';
import { withPrefix } from 'gatsby';
import React from 'react';
import { AiOutlineMail, AiOutlineTwitter } from 'react-icons/ai';
import { GoMarkGithub } from 'react-icons/go';
import { fontSize, mediaQuery } from '../../constants/style';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

export const Description = () => {
  const { author } = useSiteMetadata();

  return (
    <div css={styleMain}>
      <img css={styleImg} src={withPrefix(author.photo)} alt={author.name} />
      <div css={styleContent}>
        {author.bio}
        <br />
        <a href={'mailto:' + author.contacts.email}>
          <AiOutlineMail />
        </a>{' '}
        &nbsp;
        <a href={author.contacts.github}>
          <GoMarkGithub />
        </a>{' '}
      </div>
    </div>
  );
};

const topMargin = '30px';

const styleMain = css`
  height: auto;
  width: 100%;
`;

const styleImg = css`
  display: block;
  width: 120px;
  height: 120px;
  text-align: center;
  margin-top: ${topMargin};
  margin-left: auto;
  margin-right: auto;
`;

const styleContent = css`
  width: 60%;
  display: block;
  font-size: ${fontSize.text};
  margin-top: ${topMargin};
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  ${mediaQuery.sm} {
    width: 80%;
    font-size: calc(${fontSize.text} * 0.9);
  }

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
`;
