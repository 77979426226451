import * as React from 'react';
import { Description } from '../components/Description';
import { Feed } from '../components/Feed';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';
import { TopBar } from '../components/TopBar';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

const IndexPage = () => {
  const { title, subtitle, projects, employments, educations } = useSiteMetadata();
  return (
    <main>
      <Layout title={title} description={subtitle} useChakra={false}>
        <TopBar />
        <Description />
        <Section title="Selected Projects">
          <Feed feeds={projects} />
        </Section>
        <Section title="Employment">
          <Feed feeds={employments} />
        </Section>
        <Section title="Education">
          <Feed feeds={educations} />
        </Section>
      </Layout>
    </main>
  );
};

export default IndexPage;
