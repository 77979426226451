import { css } from '@emotion/react';
import React from 'react';
import { FeedItem } from '../../types';
import { color, fontSize } from '../../constants/style';

type Props = {
  feeds: Array<FeedItem>;
};

export const Feed = ({ feeds }: Props) => (
  <div css={styleMain}>
    {feeds.map((item: FeedItem) => {
      return (
        <div css={styleItem(item.link !== undefined)} key={item.title}>
          <h2 css={styleTitle}>
            {item.link ? (
              <a css={styleLink} href={item.link}>
                {item.title}
              </a>
            ) : (
              <p>{item.title}</p>
            )}
          </h2>
          <div css={styleMeta}>{item.meta}</div>
          <p>{item.content}</p>
        </div>
      );
    })}
  </div>
);

const styleMain = css``;

const styleItem = (withLink: boolean) => {
  if (withLink) {
    return css`
      padding: 0px 10px 10px 10px;
      margin: 3px;
      font-size: ${fontSize.text};
      transition: all 0.2s;
      border-radius: 10px;

      &:hover {
        background-color: #f0f4f5;
      }
    `;
  } else {
    return css`
      padding: 0px 10px 10px 10px;
      margin: 3px;
      font-size: ${fontSize.text};
    `;
  }
};

const styleTitle = css`
  font-size: ${fontSize.text};
  color: ${color.base};
`;

const styleLink = css`
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

const styleMeta = css`
  font-size: calc(${fontSize.text} * 0.8);
  color: ${color.secondary};
`;
